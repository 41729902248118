<template>
    <div>
        <page-title :heading="$t('accounting.lang_debitInvoices')" :subheading="$t('accounting.lang_debitInvoices')" :icon=icon></page-title>
        <div class="app-main__inner">
          <DebitInvoicesComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "@/Layout/Components/PageTitle.vue";
    import DebitInvoicesComponent from "@/components/accounting/debitinvoice/DebitInvoicesComponent";

    export default {
        components: {
            DebitInvoicesComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-search icon-gradient bg-tempting-azure',
        }),
    }
</script>